import {
  useMembers,
  useStatus,
  useScreenShare,
  Video,
} from "@signalwire-community/react";
import API from "api/index";
import Modal from "components/modal";
import PopoverComponent from "components/popover";
import {
  MicOff,
  Mic,
  VideoOff,
  VideoIcon,
  MonitorOff,
  Monitor,
  PhoneOff,
  Camera,
  Star,
} from "lucide-react";
import rateUs from "assets/images/rate.svg";
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import getCameras from "utils/getCamera";

interface VideoConstraints {
  aspectRatio: number;
  deviceId: string;
}

interface Circle {
  x: number;
  y: number;
  radius: number;
  number: number;
  color: string;
}

interface Metadata {
  height: number;
  width: number;
  circleData: Circle[];
}

const positiveCommentList = [
  "User friendly Interface",
  "Clear communication",
  "Professionalism",
];

const negativeCommentList = [
  "Poor video or audio",
  "Noisy communication",
  "Bad Experience with caller",
];

const VideoCall = () => {
  const [roomSession, setRoomSession] = useState<any>(null);
  const [roomJoined, setRoomJoined] = useState(false);
  const [open, setOpen] = useState(false);
  const { self, members } = useMembers(roomSession);
  const { active } = useStatus(roomSession);
  const displayCanvasRef = React.useRef<HTMLCanvasElement>(null);
  const { toggle, active: screenShareActive } = useScreenShare(roomSession);
  const [roomId, setRoomId] = useState<string>("");
  const [selectedOption, setSelectedOption] = React.useState("");
  const [token, setToken] = useState<string | undefined>("");
  const onRoomReady = useCallback((rs: any) => setRoomSession(rs), []);
  const [canvaSize] = useState({ width: 0, height: 0, top: 0, left: 0 });
  const [metadata, setMetadata] = useState<any>({});
  const [audioMuted, setAudioMuted] = useState<boolean>(false);
  const [videoMuted, muteVideo] = useState<boolean>(false);
  // const [showVideo, setShowVideo] = useState<boolean>(false);
  // const [canvadata, setCanvaData] = useState<database[]>([]);
  // const [startTime, setStartTime] = useState<number | null>(null);
  const [permissionError, setPermissionError] = useState<boolean>(false);
  const [roomleft, setRoomLeft] = useState<boolean>(false);
  const [cameras, setCameras] = useState<any[]>([]);

  const [videoConstraints, setVideoConstraints] = useState<VideoConstraints>({
    aspectRatio: 0,
    deviceId: "",
  });

  const [isLinkExpired, setIsLinkExpired] = useState<boolean>(false);
  const [hoveringStar, setHoveringStar] = useState<number>(0);
  const { noteUUID: noteId, invite } = useParams();
  const [rating, setRating] = useState(0);
  const [ratingSubmitted, setRatingSubmitted] = useState(false);
  const [comment, setComment] = useState("What did you like about this call?");
  const [selectedFeedbackOptions, setSelectedFeedbackOptions] = useState<
    string[]
  >([]);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isPortrait, setIsPortrait] = useState(false);

  let rect = { width: 0, height: 1, top: 0, left: 0 };
  let aspectRatio: Number = 1.77;

  const commentList = rating > 3 ? positiveCommentList : negativeCommentList;
  const handleRatingChange = (newRating: number) => {
    setRating(newRating);
    if (newRating <= 3) {
      setComment("What did you not like about this call?");
    } else {
      setComment("What did you like about this call?");
    }
    setRatingSubmitted(true);
  };

  const handleFeedbackOptionSelect = (option: string) => {
    setSelectedFeedbackOptions((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(option)) {
        return prevSelectedOptions.filter((item) => item !== option);
      } else {
        return [...prevSelectedOptions, option];
      }
    });
  };

  const createFeedback = async (values: any) => {
    const create_feedback_data = {
      noteID: values.note_id,
      roomID: values.room_id,
      rating: values.rating,
      feedback: values.feedback.toString(),
    };

    const feedback: any = await API.post(
      "/notes/createFeedback",
      create_feedback_data
    );
    return feedback;
  };

  const handleSubmit = async () => {
    // Submit the feedback form
    await createFeedback({
      note_id: noteId,
      room_id: roomId,
      rating: rating,
      feedback: selectedFeedbackOptions,
    });
    setSubmitted(true);
  };

  function canvasAndVideoPositionSync() {
    const video = document.getElementsByTagName("video")!;
    const canvasElement = document.getElementsByTagName("canvas")[0];

    video[0].style.visibility = "hidden";

    const videoIsPotraitMode = video[1].videoHeight / video[1].videoWidth > 1;

    if (videoIsPotraitMode) {
      const scaleFactorWidth = video[1].clientWidth / video[0].clientWidth;
      const scaleFactorHeight = video[1].clientHeight / video[0].clientHeight;

      const scaleFactor = Math.max(scaleFactorWidth, scaleFactorHeight);
      console.log(
        "Scale factor is",
        scaleFactorHeight,
        scaleFactorWidth,
        scaleFactor
      );

      canvasElement.width = video[0].clientWidth * scaleFactor;

      canvasElement.height = video[1].clientHeight;

      const offsetLeft =
        ((canvasElement.width - video[1].clientWidth) / 2) * -1;

      canvasElement.style.top = `${video[1].getBoundingClientRect().top}px`;

      canvasElement.style.left = `${offsetLeft}px`;
    } else {
      video[1].style.width = "";
      const newVideo1width = video[1].clientWidth;
      video[1].style.width = "100%";

      const intialVideo1Height = video[1].clientHeight;

      video[0].style.width = "";
      video[1].style.height = "";

      video[0].style.height = `${video[1].clientHeight}px`;
      const initialVideo0Width = video[0].clientWidth;

      canvasElement.width = video[0].clientWidth;
      canvasElement.height = video[0].clientHeight;

      if (newVideo1width >= window.innerWidth) {
        // if (initialVideo0Width < window.innerWidth) {
        const blackBarHeight = (intialVideo1Height - video[1].clientHeight) / 2;
        canvasElement.style.top = `${blackBarHeight}px`;
        // canvasElement.style.top = `${blackBarHeight}px`;

        const blackBarWidth = (video[0].clientWidth - video[1].clientWidth) / 2;
        canvasElement.style.left = `${-1 * blackBarWidth}px`;
      } else {
        canvasElement.style.top = `0px`;

        if (initialVideo0Width <= window.innerWidth) {
          const rect = video[0].getBoundingClientRect();
          canvasElement.style.left = `${rect.left}px`;
        } else {
          const blackBarWidth =
            (video[0].clientWidth - video[1].clientWidth) / 2;
          canvasElement.style.left = `${-1 * blackBarWidth}px`;
        }
      }

      video[1].style.height = "100%";
      video[0].style.width = "100%";
      video[0].style.height = "";
    }

    //     // Drawing the canvas once more
    let displayCanvas: HTMLCanvasElement,
      displayContext: CanvasRenderingContext2D | null;
    if (displayCanvasRef.current) {
      displayCanvas = displayCanvasRef.current;
      displayContext = displayCanvas.getContext("2d");
      if (metadata && metadata.height && metadata.circleData) {
        redrawCanvas(metadata.circleData);
      }
    }

    function redrawCanvas(circles: any[]) {
      displayContext!.clearRect(
        0,
        0,
        displayCanvas.width,
        displayCanvas.height
      );

      circles.forEach((circle) => {
        const ratioX = displayCanvas.width / circle.width;
        const ratioY = displayCanvas.height / circle.height;

        const translatedX = circle.x * ratioX;
        const translatedY = circle.y * ratioY;
        const translatedRadius = circle.radius * Math.min(ratioX, ratioY);

        displayContext!.beginPath();
        displayContext!.arc(
          translatedX,
          translatedY,
          translatedRadius,
          0,
          2 * Math.PI
        );

        displayContext!.strokeStyle = circle.color;
        displayContext!.lineWidth = 5;
        displayContext!.stroke();
        displayContext!.closePath();

        displayContext!.fillStyle = circle.color;
        displayContext!.font = "25px Arial";
        displayContext!.textAlign = "center";
        displayContext!.textBaseline = "middle";
        displayContext!.fillText(
          circle.number.toString(),
          translatedX,
          translatedY
        );
      });
    }
  }

  const handleOptionSelect: (option: string | null) => void = async (
    option
  ) => {
    if (option) {
      setSelectedOption(option);
    }
    console.log("option", selectedOption);
    await roomSession.videoMute();
    await roomSession.updateCamera({ deviceId: selectedOption });
    await roomSession.videoUnmute();
  };

  // Resize Logic
  useEffect(() => {
    const handleResize = async () => {
      rect = document.getElementById("video")!.getBoundingClientRect();
      console.log(rect);

      if (!rect.width || !rect.height) return;

      const aspectRatio = rect.width / rect.height;
      console.log("aspectRatio", aspectRatio);

      if (roomSession) {
        roomSession
          ?.updateMeta({ aspectRatio: aspectRatio })
          .then(() => {
            console.log("Meta updated successfully.");
          })
          .catch((error: any) => {
            console.log("Failed to update meta:", error);
          });

        await roomSession.setLayout({ name: "1x1" });
      }

      if (window.innerWidth / window.innerHeight < 1) {
        setIsPortrait(true);
        setVideoConstraints((prev) => ({
          ...prev,
          aspectRatio: isMobile ? 0.75 : prev.aspectRatio, // Adjust aspect ratio for mobile portrait
        }));
      } else {
        setIsPortrait(false);
        setVideoConstraints((prev) => ({
          ...prev,
          aspectRatio: isMobile ? 1.33 : prev.aspectRatio, // Adjust aspect ratio for mobile landscape
        }));
      }

      const videoElements = document.getElementsByTagName("video");

      if (videoElements.length > 0) {
        document.getElementsByTagName("video")[0].style.visibility = "hidden";
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [aspectRatio, isMobile]);

  // Check device type
  useEffect(() => {
    const isMobile = /Mobile/.test(navigator.userAgent);
    console.log("Navigator.userAgent", navigator.userAgent);

    if (isMobile) {
      setIsMobile(true);

      console.log("Loaded on a mobile device");
    } else {
      setIsMobile(false);
      console.log("Loaded on a PC");
    }
  }, []);

  useEffect(() => {
    // const videoElement = document.getElementById("video")?.getElementsByTagName("video")[0];
    // const rect = videoElement?.getBoundingClientRect();

    const rect = document.getElementById("video")!.getBoundingClientRect();

    const aspectRatio = rect.width / rect.height;
    console.log("aspectRatio", aspectRatio);

    const getToken = async () => {
      try {
        const deviceList = await getCameras();
        let deviceId = deviceList[0].deviceId;
        for (const device of deviceList) {
          if (device.label.includes("back")) {
            deviceId = device.deviceId;
            break;
          }
        }
        setSelectedOption(deviceId);
        setCameras(deviceList);
        setVideoConstraints({
          aspectRatio: aspectRatio,
          deviceId: deviceId,
        });
        const token = await API.post("/videoToken/" + noteId);
        if (token.data.token) {
          setToken(token.data.token);
        } else {
          setIsLinkExpired(true);
        }
        // setCameras(["a", "b", "c"]);
      } catch (e) {
        const eventData = {
          name: "User video permission denied error",
          eventtype: "Load",
          payload: {
            error: `${e}`,
            noteId: noteId,
            invite: invite,
          },
        };

        window.GZAnalytics && window.GZAnalytics.customLog(eventData);

        setPermissionError(true);
      }
    };

    // setToken(invite);
    getToken();
  }, []);

  useEffect(() => {
    // let displayCanvas: HTMLCanvasElement, displayContext: CanvasRenderingContext2D | null;
    // let displayCanvas: HTMLCanvasElement | null;
    if (displayCanvasRef.current) {
      // displayCanvas = displayCanvasRef.current;
      // displayContext = displayCanvas.getContext("2d");
      if (metadata && metadata.height && metadata.circleData) {
        canvasAndVideoPositionSync();
        redrawCanvas(metadata.circleData);

        const eventData = {
          name: "User canvas draw invoked",
          eventtype: "Draw",
          payload: {
            circles: metadata.circleData,
            canvasWidth: displayCanvasRef.current?.width,
            canvasHeight: displayCanvasRef.current?.height,
            windowWidth: window.innerWidth,
            windowHeight: window.innerWidth,
          },
        };

        window.GZAnalytics && window.GZAnalytics.customLog(eventData);
      }
    }

    function redrawCanvas(circles: any[]) {
      // let displayCanvas: HTMLCanvasElement, displayContext: CanvasRenderingContext2D | null;
      const displayCanvas = displayCanvasRef.current!;
      const displayContext = displayCanvas.getContext("2d");

      displayContext!.clearRect(
        0,
        0,
        displayCanvas.width,
        displayCanvas.height
      );

      circles.forEach((circle) => {
        const ratioX = displayCanvas.width / circle.width;
        const ratioY = displayCanvas.height / circle.height;

        const translatedX = circle.x * ratioX;
        const translatedY = circle.y * ratioY;
        const translatedRadius = circle.radius * Math.min(ratioX, ratioY);

        displayContext!.beginPath();
        displayContext!.arc(
          translatedX,
          translatedY,
          translatedRadius,
          0,
          2 * Math.PI
        );

        displayContext!.strokeStyle = circle.color;
        displayContext!.lineWidth = 5;
        displayContext!.stroke();
        displayContext!.closePath();

        displayContext!.fillStyle = circle.color;
        displayContext!.font = "25px Arial";
        displayContext!.textAlign = "center";
        displayContext!.textBaseline = "middle";
        displayContext!.fillText(
          circle.number.toString(),
          translatedX,
          translatedY
        );
      });
    }
  }, [metadata]);

  const leaveRoom = async () => {
    if (!roomSession) {
      return;
    }
    try {
      await roomSession.leave();
    } catch (e) {
      console.log(e);
    } finally {
      setRoomLeft(true);
      setOpen(true);
    }
  };

  const getMetadata = (e: any) => {
    if (!roomJoined || !roomSession) return;
    if (e.room.meta.leave) {
      leaveRoom();
    }
    setMetadata(e.room.meta);
    console.log("metadata", e.room.meta);
  };

  const handleVideo = async () => {
    console.log("video", videoMuted);
    if (!roomSession) {
      return;
    }
    if (videoMuted) {
      try {
        await roomSession.videoUnmute({ memberId: self?.id });
        muteVideo(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      // self?.video.mute();
      try {
        await roomSession?.videoMute({ memberId: self?.id });
        muteVideo(true);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleAudio = async () => {
    if (!roomSession) {
      return;
    }
    if (audioMuted) {
      try {
        await roomSession?.audioUnmute({ memberId: self?.id });
        setAudioMuted(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await roomSession?.audioMute({ memberId: self?.id });
        setAudioMuted(true);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onRoomJoined = async () => {
    if (!roomSession) {
      return;
    }
    setRoomJoined(true);

    await roomSession.setLayout({ name: "1x1" });
    await roomSession.updateCamera({ deviceId: selectedOption });

    setRoomId(roomSession.roomId);

    const rect = document.getElementById("video")!.getBoundingClientRect();
    const aspectRatio = rect.width / rect.height;

    // For mobile use full screen
    const videoLayer = document.getElementsByClassName("paddingWrapper")[0];
    if (isMobile && videoLayer instanceof HTMLElement) {
      videoLayer.style.removeProperty("position");
    }
    console.log("VideoLayer", videoLayer);

    await roomSession
      ?.updateMeta({ aspectRatio: aspectRatio })
      .then(() => {
        console.log("Meta updated successfully.");
      })
      .catch((error: any) => {
        console.log("Failed to update meta:", error);
      });

    const eventData = {
      name: "User joined room",
      eventtype: "Load",
      payload: {
        roomID: roomSession.roomId,
        rectWidth: rect.width,
        rectHeight: rect.height,
      },
    };

    window.GZAnalytics && window.GZAnalytics.customLog(eventData);
  };

  if (roomSession && !submitted && !roomleft) {
    rect = document.getElementById("video")!.getBoundingClientRect();
    aspectRatio = rect.width / rect.height;
  }

  return (
    <>
      {isLinkExpired && <div>Oops ! Link is expired !</div>}
      <div className="w-screen h-screen flex flex-col items-center justify-center">
        <div className="relative w-full h-full bg-black">
          <div id="canvas-cover" className="absolute aspect-auto z-20">
            <canvas
              className="fixed"
              ref={displayCanvasRef}
              width={canvaSize.width}
              height={"200"}
            />
          </div>
          <div id="video" className="fixed top-0 left-0 right-0 bottom-0">
            {token && videoConstraints.aspectRatio && selectedOption.length && (
              <>
                <Video
                  token={token}
                  onRoomReady={(e) => onRoomReady(e)}
                  onRoomJoined={onRoomJoined}
                  onRoomUpdated={(e) => getMetadata(e)}
                  video={{ deviceId: selectedOption }}
                />
              </>
            )}
          </div>
          {!roomleft && (
            <div className="absolute bottom-[4%] left-[50%] translate-x-[-50%] flex sm:gap-6 gap-3 z-50">
              <button
                onClick={() => handleAudio()}
                className={`${
                  audioMuted
                    ? "bg-white text-black"
                    : " bg-[#e2e2e2]/20  text-white"
                } flex items-center justify-center sm:w-12 sm:h-12 h-10 w-10  rounded-md font-medium px-2 py-1`}
              >
                {audioMuted ? <MicOff /> : <Mic />}
              </button>
              <button
                onClick={() => handleVideo()}
                className={`${
                  videoMuted
                    ? "bg-white text-black"
                    : " bg-[#e2e2e2]/20  text-white"
                } flex items-center justify-center sm:w-12 sm:h-12 h-10 w-10 rounded-md font-medium px-2 py-1`}
              >
                {videoMuted ? <VideoOff /> : <VideoIcon />}
              </button>
              <PopoverComponent
                position="top"
                triggerButton={
                  <button
                    className={` bg-[#e2e2e2]/20  text-white flex items-center justify-center sm:w-12 sm:h-12 h-10 w-10 rounded-md font-medium px-2 py-1`}
                  >
                    <Camera />
                  </button>
                }
              >
                <div className="flex flex-col">
                  {cameras.map((camera, index) => {
                    return (
                      <div key={index} className="flex items-center py-2 px-4">
                        <div className="flex gap-2">
                          <input
                            id={camera.label}
                            name={camera.label}
                            type="radio"
                            checked={camera.deviceId === selectedOption}
                            onClick={() => handleOptionSelect(camera.deviceId)}
                          />
                          <label
                            htmlFor={camera.label}
                            className="cursor-pointer text-[14px]"
                          >
                            {camera.label}
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </PopoverComponent>

              <button
                disabled={!active}
                onClick={() => {
                  try {
                    toggle();
                  } catch (e) {
                    toast.error("Something went wrong !");
                  }
                }}
                className={` ${
                  !active && "opacity-50"
                } flex items-center justify-center sm:w-12 sm:h-12 h-10 w-10 rounded-md font-medium px-2 py-1  bg-[#e2e2e2]/20  text-white`}
              >
                {screenShareActive ? <MonitorOff /> : <Monitor />}
              </button>

              <button
                onClick={() => leaveRoom()}
                className="flex items-center justify-center sm:w-12 sm:h-12 h-10 w-10 rounded-md font-medium px-2 py-1  bg-red-600  text-white "
              >
                <PhoneOff />
              </button>
            </div>
          )}
          {!roomJoined && (
            <div className="flex w-full flex-col gap-2 h-full justify-center items-center">
              <div className="loader"></div>
              <h2 className="text-white">Joining into the conversation</h2>
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={open}
        onClose={() => setOpen(false)}
        showCloseButton={!submitted}
        hasBackbutton={ratingSubmitted && !submitted}
        onClickBackButton={() => setRatingSubmitted(false)}
      >
        {submitted ? (
          <div className="flex flex-col items-center gap-2 px-4 py-2">
            <h2 className="text-[24px] font-medium">
              Thank you for your feedback!
            </h2>
            <div className="flex items-center flex-col">
              <p>We appreciate your valuable input.</p>
              <p>Feel free to explore more of our services</p>
            </div>
          </div>
        ) : (
          <div className="sm:w-[380px] flex flex-col gap-6 pb-4 px-4 items-center">
            {ratingSubmitted ? (
              <div className="w-full flex flex-col items-center pt-4 gap-6">
                <h2 className="sm:text-[18px] font-medium text-center">
                  {comment}
                </h2>
                <div className="flex flex-col md:flex-row md:flex-wrap items-center justify-center gap-2">
                  {commentList.map((comments, index) => {
                    return (
                      <div
                        key={index}
                        className={` ${
                          selectedFeedbackOptions.includes(comments)
                            ? "bg-primary text-white"
                            : "hover:border-primary hover:text-primary hover:font-semibold"
                        } px-2 py-1 w-fit text-center border rounded-full  cursor-pointer`}
                        onClick={() => handleFeedbackOptionSelect(comments)}
                      >
                        {comments}
                      </div>
                    );
                  })}
                </div>
                <button
                  onClick={handleSubmit}
                  className="w-fit rounded-md font-medium px-4 mt-4 py-2 border border-primary  text-primary "
                >
                  Submit
                </button>
              </div>
            ) : (
              <div className="flex flex-col gap-1 items-center">
                <img className="w-48 h-48" src={rateUs} alt="rate_us" />
                <div className="flex flex-col gap-6 items-center">
                  <h2 className="text-[20px] font-medium">
                    Rate your experience on our service
                  </h2>
                  <div className="flex gap-4 items-center justify-center">
                    <span className=" scale-150">😔</span>
                    <div className="flex">
                      {[1, 2, 3, 4, 5].map((value) => (
                        <button
                          key={value}
                          className={`px-1`}
                          onClick={() => handleRatingChange(value)}
                          onMouseEnter={() => setHoveringStar(value)}
                          onMouseLeave={() => setHoveringStar(0)}
                        >
                          <Star
                            className={`stroke-primary w-10 h-10 ${
                              value <= rating ? "fill-primary " : ""
                            } ${value <= hoveringStar ? "fill-primary " : ""}`}
                          />
                        </button>
                      ))}
                    </div>
                    <span className="scale-150">😃</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {/* submit form */}
      </Modal>
    </>
  );
};

export default VideoCall;
